<template>
  <v-app>
    <dashboard-core-app-bar />
    <v-main>
        <v-row class="justify-center">
          <v-col cols="12" sm="10">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-book-open</template>
              <template v-slot:toptitle-text
                >{{ $t("planes_de_subscripcion") }}
              </template>
            </page-top-title>
          </v-col>
        </v-row>
      <v-container tag="section">
        <v-row justify="center" class="px-8 text-sm-justify">
        <h2 class="text-h1 my-8">Planes</h2>
          <p>La Guía Mundial de Uso de Medicamentos y Farmacogenómica (WGPGX) es un ejercicio multidisciplinar y sistemático de puesta en orden de datos organizados en bases de datos seleccionadas y miles de informes dispersos en la literatura internacional sobre genética, genómica, farmacología, metabolismo de los medicamentos, terapéutica y farmacogenómica, con el fin de proporcionar a médicos, genetistas, farmacéuticos, investigadores, profesionales de la salud, reguladores y población en general un conjunto de información práctica que no está disponible en otras publicaciones en su totalidad.</p>
          <p>La WGPGX incluye información específica de 1.395 medicamentos clasificados por orden alfabético y por categorías, ofreciendo para cada uno de ellos su nombre comercial en más de 30 países (UE, Reino Unido, EEUU , Canadá, Argentina, Brasil, México y Japón) y el análisis de 447 genes de relevancia en el campo de la farmacogenética.</p>
        </v-row>
        <v-row justify="center">
          <v-col>
            <base-material-card icon="mdi-account-outline">
              <template v-slot:after-heading>
                <div class="font-weight-light card-title text-h2 mt-2">
                  {{ $t("plan_basico_titulo") }}
                </div>
                <div class="font-weight-light  text-h3 mt-2">
                  {{ $t("plan_basico_precio") }}
                </div>
              </template>
              <ul>
                <li>{{ $t("plan_basico_1") }}</li>
                <li>{{ $t("plan_basico_2") }}</li>
                <li>{{ $t("plan_basico_3") }}</li>
              </ul>
              <v-card-actions class="d-flex justify-space-around py-6" fluid>
                <v-btn color="darkprimary" rounded class="" @click="goToDashboard">
                  {{ $t("actualizar_plan") }}
                </v-btn>
              </v-card-actions>
            </base-material-card>
          </v-col>
          <v-col>
            <base-material-card icon="mdi-account-outline">
              <template v-slot:after-heading>
                <div class="font-weight-semibold card-title text-h2 mt-2">
                  {{ $t("plan_avanzado_titulo") }}
                </div>
                <div class="font-weight-light text-h3 mt-2">
                  {{ $t("plan_avanzado_precio") }}
                </div>
              </template>
              <ul>
                <li>{{ $t("plan_avanzado_1") }}</li>
                <li>{{ $t("plan_avanzado_2") }}</li>
                <li>{{ $t("plan_avanzado_3") }}</li>
                <li>{{ $t("plan_avanzado_4") }}</li>
              </ul>
              <v-card-actions class="d-flex justify-space-around py-6" fluid>
                <v-btn color="darkprimary" rounded class="" @click="goToDashboard">
                  {{ $t("actualizar_plan") }}
                </v-btn>
              </v-card-actions>
            </base-material-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import axios from "@/services/axios.js";
export default {
  data() {
    return {};
  },
  components: {
    PageTopTitle,
    DashboardCoreAppBar: () => import("./components/core/AppBar")
  },
  computed: {},
  methods: {
    goToDashboard() {
      let self = this;
      return axios
        .post(
          "/.netlify/functions/customer-portal",
          {}
        )
        .then(function(resp) {
          window.location.href = resp.data.url;
        });
    },
  }
};
</script>
